/* Add General Page Styles */
body,html {
    margin: 0;
    font-family:'Lato';
    background-color: #f4f4f4;
}

.logout-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 12px 24px; /* Increased padding for better appearance */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px; /* Increased font size for readability */
    margin: 15px 0; /* Increased margin for spacing */
    cursor: pointer;
    border-radius: 6px; /* Slightly larger border-radius for a softer look */
    transition: background-color 0.3s, transform 0.3s; /* Added transition for scaling effect */
    font-family:'Lato';
}

.logout-button:hover {
    background-color: #c62828;
    transform: scale(1.05); /* Slightly enlarges the button on hover */
    font-family:'Lato';
}

.logout-button:focus {
    outline: none; /* Removes default focus outline */
    box-shadow: 0 0 0 3px rgba(244, 67, 54, 0.3); /* Adds a focus ring with the button color */
}

/* General Container Styles */
.dropdown-container {
    font-family: Arial, sans-serif;
    padding: 30px;
    max-width: 1200px;
    margin: auto;
    background-color: white;
/* Slightly lighter background for better contrast */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Slightly stronger shadow for a more pronounced effect */
    position: relative; /* Ensure absolute positioning of elements within */
    font-family:'Lato';
}

/* Logout Button Container Styles */
.logout-button-container {
    position: absolute; /* Positioned relative to .dropdown-container */
    top: 15px; /* Distance from top */
    right: 15px; /* Distance from right */
    font-family:'Lato';
}

/* Heading Styles */
.heading {
    text-align: center;
    color: #68d388; /* Blue color for the heading */
    margin-bottom: 30px;
    font-size: 2.5rem; /* Large font size for prominence */
    font-weight: 700; /* Bold font weight for emphasis */
    font-family:'Lato';
}

/* Filters Container Styles */
.filters {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
    font-family:'Lato';
}

/* Select Dropdown Styles */
.filter-select {
    padding: 12px;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid #68d388;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
    color: #333;
    transition: border-color 0.3s, box-shadow 0.3s;
    font-family:'Lato';
}

.filter-select:focus {
    border-color: #68d388;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    outline: none;
    font-family:'Lato';
}

/* Buttons Styles */
.submit-button,
.register-button {
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 8px;
    border: none;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    margin: 0 10px;
    font-family:'Lato';
}

.submit-button {
    background-color: #68d388;
}

.submit-button:hover {
    background-color: #0056b3;
    transform: scale(1.02);
}

.submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.register-button {
    background-color: #28a745;
}

.register-button:hover {
    background-color: #218838;
    transform: scale(1.02);
}

/* Table Styles */
.institutes-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    font-family:'Lato';
}

.institutes-table th, .institutes-table td {
    padding: 15px;
    text-align: left;
    font-size: 16px;
}

.institutes-table th {
    background-color: #68d388; /* Blue background for headers */
    color: white;
    border-bottom: 2px solid #68d388;
}

.institutes-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.institutes-table tbody tr:hover {
    background-color: #e1e1e1;
}

.institutes-table td {
    border-bottom: 1px solid #ddd;
}

/* Sign-In Button Styles */
.signin-button {
    background-color: ##68d388; /* Blue color */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
    transition: background-color 0.3s, transform 0.3s; /* Added transition for hover effect */
}

.signin-button:hover {
    background-color: #68d388; /* Darker shade of blue */
    transform: scale(1.05); /* Slightly enlarges the button on hover */
}

.container h2 {
    font-size: 2rem;
    color: #333;    font-family:'Lato';
}

.container p {
    font-size: 16px;
    color:#555;    font-family:'Lato';
}

/* Add this to Dropdown.css */
/* Adjust general page styles if needed */
.branch-select {
    width: 300px; /* Adjust width as needed */
}

/* Styling for react-select elements */
.react-select__control {
    border-radius: 8px;
    border: 1px solid #68d388;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.react-select__menu {
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.react-select__option--is-selected {
    background-color: #68d388;
    color: white;
}

.react-select__multi-value {
    background-color: #68d388;
    color: white;
}

.react-select__multi-value__label {
    color: white;
}

.react-select__multi-value__remove {
    color: white;
}

.react-select__indicator-separator {
    display: none;
}
