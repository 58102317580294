.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto; /* Only take up as much space as the modal */
    background-color: transparent; /* No background overlay */
    display: flex;
    align-items: flex-start; /* Align at the top */
    justify-content: center; /* Center horizontally */
    z-index: 1000;    font-family:'Lato';
  }
  
  .modal {
    position: fixed;
    top: 0;
    justify-content: center; 
    background-color: #68d388;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: auto;
    margin: 10px; /* Add some margin from the top */
    border: 1px solid #68d388;
    font-family:'Lato';
  }
  
  .modal p {
    margin: 0;
    font-size: 16px;
    color: black;    font-family:'Lato';
  }
  