body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: white; /* Grey Blue Green background color */
    color: #222629; /* Dark text color for contrast */
    overflow-x: hidden;
    font-family:'Lato';
}

/* Full screen container */
.total {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: white;
}

/* Header styling */
.homepage-header {
    background-color: white; /* Grey Blue Green background color */
/* Brightly Orange Number 2 background */
    color: #ffb766; /* Grandpa Orange text color */
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-bottom: none;
    font-family:'Lato';
}

.homepage-header h1 {
    font-size: 2.5rem;
    margin: 0;
    color: black; /* White text color for header title */
    font-family:'Lato';
}

/* Header buttons */
.header-buttons {
    display: flex;
    gap: 10px;
}

.header-button {
    background-color: white; /* Grey Blue Green background color *//* Live Green button background */
    color: #222629; /* Dark text color */
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family:'Lato';
    background-color: #68d388;
}

.header-button:hover {
    background-color: white; /* Grandpa Orange on hover */
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Main content area */
.homepage-main {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

/* Unified container */
.content-container {
    display: flex;
    width: 80%;
    height: 70%;
    background-color: rgb(236, 120, 81); /* Grandpa Orange background */
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
}

/* Image container - left half */
.image-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white; /* White background for image container */
    padding: 20px;
}

.image-container img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
}

/* Right half - card container */
.card {
    flex: 1;
    display: flex;
    flex-direction: row; /* Changed to row for side-by-side buttons */
    justify-content: space-between; /* Space between the two buttons */
    align-items: center;
    padding: 40px;
    background-color: white; /* Brightly Orange Number 2 background */
    color: black; /* White text color */
    box-sizing: border-box;
}

.card-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%; /* Adjust width to fit two buttons side by side */
}

.card-paragraph {
    font-size: 1rem;
    color: #222629; /* Dark text color */
    margin-bottom: 10px;
    text-align: center;
    font-family:'Lato';
}

.homepage-button-1 {
    width: 100%;
    padding: 15px;
    font-size: 1.2rem;
    color: #222629; /* Dark text color */
    background-color: #68d388; /* Live Green button background */
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 21px;
    margin-bottom: 20px;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family:'Lato';
}

.homepage-button-2 {
    width: 100%;
    padding: 15px;
    font-size: 1.2rem;
    color: #222629; /* Dark text color */
    background-color: #68d388; /* Live Green button background */
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family:'Lato';
}

.homepage-button:hover {
    background-color: #ffb766; /* Grandpa Orange on hover */
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

@media (max-width: 992px) {
    .content-container {
        flex-direction: column;
    }

    .image-container,
    .card {
        width: 100%;
        height: 50%;
    }

    .image-container {
        border-right: none;
        border-bottom: 2px solid #68d388; /* Live Green separation */
    }
    
    .header-buttons {
        flex-direction: column;
        gap: 5px;
    }
}
/* Add this to your existing CSS */

.logo-container {
    display: flex;
    align-items: center;
}

.logo-video {
    width: 80px; /* Adjust size as needed */
    height: 50px;
    margin-right: 10px; /* Space between logo and text */
    border-radius: 50%; /* Optional: makes the video circular */
}

/* Existing styles... */

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    background-color:white; /* Light background color */
    overflow: hidden; /* Hide overflow for smooth animation */
    position: relative; /* Ensure relative positioning for animation */
}

.logo-carousel {
    display: flex;
    gap: 20px;
    animation: rotate 1000s linear infinite;
    white-space: nowrap; /* Ensure images are on a single line */
}

.logo-carousel img {
    width: 100px; /* Adjust size as needed */
    height: auto;
    object-fit: contain;
}

@keyframes rotate {
    0% {
        transform: translateX(0%); /* Start at the current position */
    }
    100% {
        transform: translateX(-100%); /* Move to the left */
    }
}

  