.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure modal is on top */
    font-family:'Lato';
}

.modal-content {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 300px;
    width: 100%;
    position: relative;
    z-index: 10; /* Ensure modal content is above the overlay */
    font-family:'Lato';
}

/* Updated close button */
.close-container {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    font-family:'Lato';
}

.leftright, .rightleft {
    height: 4px;
    width: 20px;
    position: absolute;
    right :5px;
    top: 10px;
    background-color: #F4A259; /* Soft Orange */
    border-radius: 2px;
    transition: all 0.3s ease-in;
    font-family:'Lato';
}

.leftright {
    transform: rotate(45deg);
}

.rightleft {
    transform: rotate(-45deg);
}

label.close {
    color: white;
    font-family: Helvetica, Arial, sans-serif; 
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: all 0.3s ease-in;
    opacity: 0;
    position: absolute;
    top: 60px;
    left: 5px;
    font-family:'Lato';
}

.close-container:hover .leftright,
.close-container:hover .rightleft {
    transform: rotate(-45deg) rotate(45deg); /* Flip directions */
    background-color: #F25C66; /* Tomato Red */
}

.close-container:hover label {
    opacity: 1;
}

/* Other styles */
.error-message {
    color: #ff4d4f;
    font-weight: bold;
    margin-top: 15px;
    text-align: center;
    font-family:'Lato';
}

.otp-verified-message {
    color: #68d388;
    font-weight: bold;
    text-align: center;
    font-family:'Lato';
}

body {
    background-color:white; /* Medium Blue */
    font-family:'Lato';
}
