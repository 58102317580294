/* Contacts Page Styles */
.contacts-container {
    font-family:'Lato';
    padding: 30px;
    max-width: 1200px;
    margin: auto;
    background-color: white; /* White background for container */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative; /* Ensure absolute positioning of elements within */
}

.logout-button-container {
    position: absolute; /* Positioned relative to .contacts-container */
    top: 15px; /* Distance from top */
    right: 15px; /* Distance from right */
    font-family:'Lato';
}

.contacts-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    font-family:'Lato';
}

.contact-card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    width: 250px;
    height: 150px;
    perspective: 1000px; /* Adds depth for 3D effect */
    cursor: pointer;
    transition: transform 0.6s; /* Smooth flip transition */
    font-family:'Lato';
}

.contact-card.flipped .contact-card-inner {
    transform: rotateY(180deg); /* Rotate card to show the back */
}

.contact-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.6s;
    font-family:'Lato';
}

.contact-card-front, .contact-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family:'Lato';
}

.contact-card-front {
    background-color: #f9f9f9;
}

.contact-card-back {
    background-color: #ffb766; /* Blue background for the back */
    color: white;
    transform: rotateY(180deg); /* Rotate back side */
}

.profile-photo img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    object-fit: cover;
}

.contact-details, .contact-phone {
    margin: 10px;
}

.contact-name {
    font-size: 18px;
    font-weight: bold;
    margin: 5px 0;
    font-family:'Lato';
}

.contact-institute, .contact-branch, .contact-phone {
    font-size: 14px;
    color: #555;
    font-family:'Lato';
}

.heading {
    text-align: center;
    color: #007bff; /* Blue color for the heading */
    margin-bottom: 30px;
    font-size: 2.5rem; /* Large font size for prominence */
    font-weight: 700; /* Bold font weight for emphasis */
    font-family:'Lato';
}

.logout-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 12px 24px; /* Increased padding for better appearance */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px; /* Increased font size for readability */
    margin: 15px 0; /* Increased margin for spacing */
    cursor: pointer;
    border-radius: 6px; /* Slightly larger border-radius for a softer look */
    transition: background-color 0.3s, transform 0.3s; /* Added transition for scaling effect */
    font-family:'Lato';
}

.logout-button:hover {
    background-color: #c62828;
    transform: scale(1.05); /* Slightly enlarges the button on hover */
}

.logout-button:focus {
    outline: none; /* Removes default focus outline */
    box-shadow: 0 0 0 3px rgba(244, 67, 54, 0.3); /* Adds a focus ring with the button color */
}