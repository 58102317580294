.registration-form-container {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    min-height: 100vh;
    background-color: #f4f7f6;
    padding: 20px;
    font-family:'Lato';
}

.header {
    text-align: center;
    margin-bottom: 30px; /* Space between header and form */    font-family:'Lato';
}

.header-image {
    max-width: 50%;
    height: auto;
    border-radius: 10px;    font-family:'Lato'; /* Optional: add rounded corners to the image */
}

.header-text {
    font-size: 20px;
    color: #333;
    margin-top: 10px;
    font-weight: bold;    font-family:'Lato'; /* Make the sentence bold */
}

.registration-form {
    background: #fff;
    border-radius: 10px; /* Increased border radius for a softer look */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Stronger shadow for a more pronounced effect */
    padding: 30px; /* More padding for a spacious look */
    width: 100%;
    max-width: 600px;
    margin-top: 1px;    font-family:'Lato';
}

.heading {
    font-size: 26px; /* Slightly larger font size */
    margin-bottom: 25px; /* Increased margin for better spacing */
    color: #333;
    text-align: center;
    font-weight: bold;    font-family:'Lato'; /* Make the heading bold */
}

label {
    display: block;
    margin-bottom: 20px;     font-family:'Lato';/* More space between form elements */
}

label span {
    display: block;
    font-size: 15px; /* Slightly larger font size */
    color: #333; /* Darker text color */
    margin-bottom: 8px; /* Increased margin below labels */
    font-weight: 500;    font-family:'Lato'; /* Medium font weight for labels */
}

input[type="text"],
input[type="email"],
select {
    width: 100%;
    padding: 12px; /* Larger padding for bigger input fields */
    border: 1px solid #ddd;
    border-radius: 6px; /* Slightly larger border radius */
    font-size: 16px;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;    font-family:'Lato'; /* Smooth transition */
}

input[type="text"]:focus,
input[type="email"]:focus,
select:focus {
    border-color: #68d388;
    outline: none;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);    font-family:'Lato'; /* Slightly stronger shadow on focus */
}

select {
    appearance: none; /* Remove default dropdown arrow for custom styling */
    background: #fff url('data:image/svg+xml;base64,...') no-repeat right 12px center; /* Custom dropdown arrow */
    background-size: 12px;    font-family:'Lato';
}

button {
    width: 100%;
    padding: 12px; /* Larger padding for button */
    border: none;
    border-radius: 6px; /* Increased border radius */
    background-color: #68d388;
    color: #fff;
    font-size: 18px; /* Larger font size for the button */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;    font-family:'Lato'; /* Added transition for button effects */
}

button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);     font-family:'Lato';/* Slight lift effect on hover */
}

button:disabled {
    background-color: #ddd;
    cursor: not-allowed;    font-family:'Lato';
}

.error-message {
    color: #ff4d4f; /* Red color for error messages */
    font-weight: bold; /* Bold error messages */
    margin-top: 15px;
    text-align: center;    font-family:'Lato';
}

@media (max-width: 768px) {
    .registration-form-container {
        padding: 15px; /* Adjust padding for mobile */
    }
    
    .registration-form {
        padding: 20px;
        max-width: 100%;
    }
}
button.resend-otp {
    background-color: #ffa34d; /* Red color for Resend OTP button */
    color: #fff; /* Keep text color white */
    transition: background-color 0.3s ease, transform 0.2s ease;     font-family:'Lato';/* Ensure transition is smooth */
}

button.resend-otp:hover {
    background-color: #ffa34d; /* Slightly darker red on hover */
    transform: translateY(-2px);     font-family:'Lato';/* Maintain the lift effect on hover */
}
